import URlS from "~/api/urls";
import { CookieOptionsValues } from "~/types/entities";

export async function setCookies(
    cookiesBody: CookieOptionsValues
  ): Promise<void> {
    const url = URlS.setCookies;
    return await $fetch(url, {
      method: "POST",
      body: cookiesBody,
    });
  }